import React from 'react';
import { PackageTag } from './styled-component';

const LABEL = {
  ACTIVE: 'ระหว่างใช้งาน',
  INACTIVE: 'ไม่ได้ใช้งาน',
  EXPIRED: 'หมดอายุ',
};

export function renderPackageTag(status) {
  return <PackageTag status={status}>{LABEL[status]}</PackageTag>;
}
