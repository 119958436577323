import { Modal } from 'antd';
import styled from 'styled-components';
import { color } from '../../resources';

const MARGIN_MODAL = 40;
const HEADER_HEIGHT = 56;
const FOOTER_HEIGHT = 60;

export const ModalAntd = styled(Modal)`
  top: 0;
  max-height: 100vh;

  .ant-modal-content {
    max-width: 100vw;
    margin: 40px 0 40px 0;
  }

  .ant-modal-header {
    height: ${HEADER_HEIGHT}px;
    border-bottom: 1px solid ${color.grey200} !important;
  }

  .ant-modal-body {
    padding: 24px;
    height: 100vh;
    max-height: calc(100vh - ${2 * MARGIN_MODAL + HEADER_HEIGHT + FOOTER_HEIGHT}px);
    overflow-y: auto;
  }

  .ant-modal-footer {
    min-height: ${FOOTER_HEIGHT}px;
  }
`;
