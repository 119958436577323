import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import { useMemo } from 'react';
import { Radio, Space } from 'antd';
import { renderTypeError } from '../index.jsx';

const RadioComponent = ({ control, item, handleChange }) => {
  const { rules, name, defaultValue, label, disabled, properties, dataCy, ...propsInput } = item;

  const { options } = properties;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const opt = useMemo(() => {
    return options.map((e) => {
      return (
        <Radio key={`radio${name}_${e.value}`} value={e.value}>
          {e.label}
        </Radio>
      );
    });
  }, [options]);

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Radio.Group
        id={name}
        disabled={disabled}
        value={value}
        optionType={properties.optionType || 'default'}
        buttonStyle={properties.buttonStyle || 'outline'}
        onChange={(e) => handleChange(e, item)}
        {...dataCy}
        {...propsInput}
      >
        <Space direction={properties.direction || 'horizontal'}>{opt}</Space>
      </Radio.Group>
      <br />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedRadio = RadioComponent;
