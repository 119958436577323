import React from 'react';
import { SideBarStyle } from '../../style';
import { ROUTES_MENU } from '../../../../resources/routes-name';
import { useSelectMenu } from '../../hook/useSelectMenu';
import { Menu } from 'antd';
import { ScrollMenu } from './style';
import BrandBanner from './views/brand-banner';
import UserAvatar from './views/user-avatar';
import { SIDEBAR_WIDTH } from '../../../../resources/constants/component-size';

const SiderLayout = ({ collapsed, getTheme, sidebarMode }) => {
  const { defaultSelectedKeys, defaultOpenKeys, onHandleClick } = useSelectMenu();
  return (
    <SideBarStyle
      width={SIDEBAR_WIDTH}
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        ...getTheme.sidebar[sidebarMode],
      }}
    >
      <BrandBanner />
      <UserAvatar />

      {defaultOpenKeys && defaultSelectedKeys && (
        <ScrollMenu>
          <Menu
            theme={sidebarMode}
            mode="inline"
            defaultOpenKeys={defaultOpenKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            style={{ ...getTheme.sidebar[sidebarMode] }}
            items={ROUTES_MENU.filter((e) => !e.hidden)}
            onClick={onHandleClick}
          />
        </ScrollMenu>
      )}
    </SideBarStyle>
  );
};

export default SiderLayout;
