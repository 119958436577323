import { useQuery } from '@tanstack/react-query';
import * as optionsService from '../services';

const optionKeys = [
  'stage-manual',
  'stage-auto',
  'key-success',
  'purchase-occasion',
  'job-cat',
  'competitors',
  'know-us',
  'quotation',
  'admin',
];

export const useAllOptionsQuery = () => {
  const optionQueries = optionKeys.map((key) =>
    useQuery({
      queryKey: [key],
      queryFn: () => optionsService.getOption(key),
      staleTime: Infinity,
    }),
  );

  const isAllOptionsLoading = optionQueries.some((query) => query.isLoading);

  const formattedOptions = optionKeys.reduce((acc, key, index) => {
    const formattedKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    acc[formattedKey] = optionQueries[index];
    return acc;
  }, {});

  return {
    formattedOptions,
    isAllOptionsLoading,
  };
};
