import { useQuery } from '@tanstack/react-query';
import * as historyApi from '../services';

export const useHistoryCompanyListQuery = (companyId) => {
  const query = useQuery({
    queryKey: ['historyCompanyData', companyId],
    queryFn: () => historyApi.getCallHistoryCompany(companyId),
  });

  return query;
};

export const useHistoryContactListQuery = (contactId) => {
  const query = useQuery({
    queryKey: ['historyContactData', contactId],
    queryFn: () => historyApi.getCallHistoryContact(contactId),
  });

  return query;
};
