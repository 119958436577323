import React from 'react';
import ContactActionDropdown from './contact-action-dropdown';
import { Icon } from '../../../../../resources';
import Row from '../../../../../components/row';
import Text from '../../../../../components/text-new';
import { ButtonTheme } from '../../../../../components/buttons';
import { EmptyColumn } from '../../table/components/render-column';

export function ContactName({ data, records, align = 'center' }) {
  if (!data) {
    return <EmptyColumn />;
  }

  return (
    <Row isFluid alignItems="center" justifyContent={align} gap="8px">
      <Text variant="span">{data}</Text>
      {records?.isFavorite && <Icon.userFill />}
    </Row>
  );
}

export function ContactActions({ actions }) {
  return (
    <Row isFluid alignItems="center" justifyContent="center" gap="8px">
      <ButtonTheme
        isCircle
        useFor="CUSTOM"
        type="primary"
        icon={<Icon.callOutline />}
        onClick={actions.onToggleCall}
      />
      <ContactActionDropdown actions={actions} />
    </Row>
  );
}
