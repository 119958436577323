import React, { createContext, useContext } from 'react';
import { useAllOptionsQuery } from '../hooks/use-options-query';

const OptionsContext = createContext();

export const OptionsProvider = ({ children }) => {
  const { formattedOptions, isAllOptionsLoading } = useAllOptionsQuery();

  return (
    <OptionsContext.Provider
      value={{
        options: {
          ...formattedOptions,
        },
        isAllOptionsLoading,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

export const useOptionsContext = () => useContext(OptionsContext);
