import { GET } from '../../../services';

export const getCallHistoryCompany = async (companyId) => {
  try {
    const response = await GET(`/tls-company/history/${companyId}`);
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

export const getCallHistoryContact = async (contactId) => {
  try {
    const response = await GET(`/company-contact/history/${contactId}`);
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};
