const { color } = require('../color');

export const OCCASION_LABEL = {
  high: 'สูง',
  medium: 'กลาง',
  low: 'ต่ำ',
};

export const OCCASION_STATUS_COLOR = {
  high: color.green80,
  medium: color.yellowStatus,
  low: color.redStatus,
};
