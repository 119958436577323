import styled from 'styled-components';

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DotElement = styled.span`
  display: inline-block;
  border-radius: 50%;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  margin-right: ${(props) => `${props.margin}px`};
  margin-left: ${(props) => `${props.margin}px`};
  background-color: ${(props) => props.color};
`;
