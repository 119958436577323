import React from 'react';
import { useSelector } from 'react-redux';
import * as adminUtils from '../../../../../../utils/admin/role';
import Column from '../../../../../column';

function UserAvatar() {
  const { user } = useSelector((state) => state.authen);

  return (
    <Column
      justifyContent="center"
      alignItems="center"
      gap="8px"
      style={{ textAlign: 'center', padding: '16px' }}
    >
      <span
        style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {user?.email}
      </span>
      <span>{adminUtils.getRoleDisplay(user?.role)}</span>
    </Column>
  );
}

export default UserAvatar;
