import React from 'react';
import PropTypes from 'prop-types';
import { ColumnWrapper } from './styled-component';

const Column = (props) => {
  const { children, isFluid, justifyContent, alignItems, gap, style } = props;
  return (
    <ColumnWrapper
      isFluid={isFluid}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      style={style}
    >
      {children}
    </ColumnWrapper>
  );
};

Column.propTypes = {
  isFluid: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.any,
  gap: PropTypes.string,
  justifyContent: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between']),
  alignItems: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between']),
};

Column.defaultProps = {
  isFluid: false,
  children: null,
  style: null,
  gap: null,
  justifyContent: 'center',
  alignItems: 'flex-start',
};

export default Column;
