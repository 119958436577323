import '../styles/header.css';
import imgLogin from '../../../assets/images/imgLogin.jpg';
import { FORM_INPUT } from '../forms/forms';
import { RenderForm } from '../../../components/forms';
import { ContainerButton } from '../../../styles/global-style';
import { ButtonTheme } from '../../../components/buttons';
import { Divider } from 'antd';
import { Icon, color } from '../../../resources';
import { TextLarge, TextLogoLogin } from '../../../components/text';

const Header = () => {
  return (
    <div className="full-width-center">
      <div className='flex-center'>
        <TextLogoLogin text='Welcome back' bold />
        <div style={{ placeSelf: 'center' }}>
          <Icon.wavingHand style={{ fontSize: '24px', color: color.yellow }} />
        </div>
      </div>
      <TextLarge text='To-do-list system' />
    </div>
  )
}

export const LoginForm = (props) => {
  const { onLogin, goToExternalLogin, control, setValue, getValues, handleSubmit } = props;
  return (
    <div style={{ flex: 1, padding: '2%' }}>
      <Header />
      <RenderForm
        control={control}
        forms={FORM_INPUT()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton center>
        <ButtonTheme useFor='LOGIN' onClick={handleSubmit(onLogin)} />
      </ContainerButton>
      <Divider />
      <ContainerButton center>
        <ButtonTheme useFor='CUSTOM' title='External Login' icon={<Icon.external />} type='primary' color={color.edit} onClick={goToExternalLogin} />
      </ContainerButton>
    </div>
  )
}

export const ImageLogin = () => {
  return (
    <div className='img-login-box'>
      <img
        className='img-login'
        alt='example'
        src={imgLogin}
      />
    </div>
  )
}

export const LoginCard = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <div className='flex-login-box'>
        <div style={{ margin: 'auto' }}>
          <div className='login-box'>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}