import { useState } from 'react';
import { theme } from 'antd';
import { setStored } from '../store/useLocalStored';
import { getStored } from './../store/useLocalStored';
import { useEffect } from 'react';
import { color } from './color';
import { themeConfig } from './constants/theme';

const themeColor = color.theme;

export const getTheme = {
  ...themeConfig,
};

export const useTheme = () => {
  const globalTheme = {
    token: {
      //antD property
      colorPrimary: themeColor,
    },
  };

  const [sidebarMode, setSidebarMode] = useState(getStored('theme') || 'light');
  const [algorithm, setAlgorithm] = useState({
    algorithm: theme.defaultAlgorithm,
  });

  useEffect(() => {
    if (getStored('theme') === 'dark') {
      setSidebarMode(getStored('theme'));
      setAlgorithm({ algorithm: theme.darkAlgorithm });
    }
  }, []);

  useEffect(() => {
    console.log('sidebarmode', sidebarMode);
  }, [sidebarMode]);

  const switchTheme = () => {
    const check = sidebarMode === 'dark';
    let mode = check ? 'light' : 'dark';
    let algo = check ? { algorithm: theme.defaultAlgorithm } : { algorithm: theme.darkAlgorithm };
    setStored('theme', mode);
    setSidebarMode(mode);
    setAlgorithm(algo);
  };

  return {
    sidebarMode,
    switchTheme,
    globalTheme: { ...globalTheme, ...algorithm },
  };
};
