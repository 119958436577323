import React from 'react';
import { ButtonTheme } from '../../../../components/buttons';
import { ModalTheme } from '../../../../components/modal';
import { color } from '../../../../resources';
import { Space } from 'antd';
import EditForm from '../../components/edit-form';
import { useFormState } from '../../components/edit-form/hooks/useFormState';
import { useAddContact } from '../../hooks/use-contact-query';

function AddModal(props) {
  const { companyId, ...modalProps } = props;

  const { mutate: addContact, isLoading: isSubmitLoading } = useAddContact({
    onCallback: props.onToggle,
  });

  const { formProps, onCancel, onSubmit } = useFormState({
    onAction: (formValues) => {
      addContact({ companyId, ...formValues });
    },
  });

  const onCloseModal = () => {
    onCancel({
      onAfterFinish: props.onToggle,
    });
  };

  return (
    <ModalTheme
      {...modalProps}
      width={1000}
      title="เพิ่มผู้ติดต่อ"
      footer={<Footer onCancel={onCloseModal} onSubmit={onSubmit} />}
      onToggle={onCloseModal}
    >
      <EditForm {...formProps} />
    </ModalTheme>
  );
}

function Footer({ onCancel, onSubmit }) {
  return (
    <Space>
      <ButtonTheme
        useFor="CUSTOM"
        title="ยกเลิก"
        type="primary"
        color={color.grey400}
        onClick={onCancel}
      />
      <ButtonTheme
        useFor="CUSTOM"
        title="เพิ่มผู้ติดต่อ"
        type="primary"
        color={color.blue80}
        onClick={onSubmit}
      />
    </Space>
  );
}

export default AddModal;
