import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { SHORT_MONTH } from '../../resources/constants/date';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Bangkok');

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const getCurrentTime = () => {
  return new Date();
};

export const formatDate = (date) => {
  if (!date) return null;
  return dayjs(date).tz().format(DEFAULT_DATE_FORMAT);
};

export const formatDateCustom = ({ date, format }) => {
  if (!date) return null;

  return dayjs(date).format(format || DEFAULT_DATE_FORMAT);
};

export const formatDateCustomWithInputFormat = ({ date, inputFormat = 'DD/MM/YYYY', format }) => {
  if (!date) return null;

  return dayjs(date, inputFormat).format(format || DEFAULT_DATE_FORMAT);
};

export const formatDateToThaiCustom = ({ date, format }) => {
  dayjs.locale('th');
  try {
    return dayjs(date)
      .tz()
      .format(format || DEFAULT_DATE_FORMAT);
  } catch (error) {
    return '';
  }
};

export const parseDateStringToThai = (dateString) => {
  dayjs.locale('th');
  try {
    return dayjs(dateString).tz();
  } catch (error) {
    return null;
  }
};

export const isValidThaiDate = (dateString) => {
  dayjs.locale('th');
  return dayjs(dateString).tz().isValid();
};

export const getCurrentThaiDate = (format = DEFAULT_DATE_FORMAT) => {
  dayjs.locale('th');
  return dayjs().tz().format(format);
};

export const addDaysToDateThai = (date, days, format = DEFAULT_DATE_FORMAT) => {
  dayjs.locale('th');
  return dayjs(date).tz().add(days, 'day').format(format);
};

export const fullThaiDateShortMonth = (date) => {
  if (!date) return '-';

  const today = dayjs(date).tz().date();

  return `${today} ${SHORT_MONTH[dayjs(date).tz().month()]} ${dayjs(date).tz().year()}`;
};

export const formatSecondsToTime = (seconds, format = 'HH:mm:ss') => {
  if (isNaN(seconds)) {
    return null;
  }

  if (seconds >= 3600) {
    return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
  }
  return dayjs.duration(seconds, 'seconds').format(format);
};

export const formatToDate = (date) => {
  return dayjs(date, DEFAULT_DATE_FORMAT).tz().toDate();
};

export const checkPastDate = (current) => {
  return current && current < dayjs().startOf('day');
};

export const diffDateFromNow = (startDate) => {
  if (!startDate) return 0;

  return dayjs().diff(dayjs(startDate), 'second');
};

export const diffDate = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;

  return dayjs(endDate).diff(dayjs(startDate), 'second');
};
