import { color } from '../../resources';
import { STATUS, VERIFY_MESSAGE } from '../../resources/constants/company';
import { env } from '../env/config';

export const renderVerifyBadgeName = (statusCompany) => {
  switch (statusCompany) {
    case STATUS.silver:
      return VERIFY_MESSAGE.silver;
    case STATUS.blue:
      return VERIFY_MESSAGE.blue;
    case STATUS.official:
      return VERIFY_MESSAGE.official;
    default:
      return '-';
  }
};

export const getBadgeColor = (status) => {
  switch (status) {
    case STATUS.silver:
      return color.grey100;
    case STATUS.blue:
      return color.oldblue;
    case STATUS.official:
      return color.warningGreen;

    default:
      return;
  }
};
