import React from 'react';
import {
  BsFillCloudArrowDownFill,
  BsSearch,
  BsFillPenFill,
  BsFillTrashFill,
  BsBookHalf,
  BsFillXCircleFill,
  BsSave2Fill,
  BsPrinterFill,
  BsFillCheckCircleFill,
  BsLockFill,
  BsArrowLeftCircleFill,
  BsFillPlusCircleFill,
  BsArrowClockwise,
  BsShieldFillCheck,
  BsPersonCheckFill,
  BsKanban,
  BsJournalBookmarkFill,
  BsLayoutSidebarInsetReverse,
} from 'react-icons/bs';
import { RiLogoutBoxRFill, RiLoginBoxFill } from 'react-icons/ri';
import { IoSettingsOutline, IoBookmarks, IoAlertCircleSharp, IoCheckbox } from 'react-icons/io5';
import { HiDotsCircleHorizontal, HiOutlineExternalLink } from 'react-icons/hi';
import { FaChevronCircleDown, FaExternalLinkAlt, FaUser } from 'react-icons/fa';
import { MdWavingHand } from 'react-icons/md';
import { BiPhone, BiDotsVerticalRounded } from 'react-icons/bi';
import { FaPhone } from 'react-icons';
import {
  AiFillCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineUser,
  AiOutlineForm,
  AiOutlineEdit,
  AiOutlineHistory,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
/**
 * React Icons --> https://react-icons.github.io/react-icons/
 */
export const Icon = {
  download: (props) => <BsFillCloudArrowDownFill {...props} />,
  search: (props) => <BsSearch {...props} />,
  edit: (props) => <BsFillPenFill {...props} />,
  remove: (props) => <BsFillTrashFill {...props} />,
  view: (props) => <BsBookHalf {...props} />,
  clear: (props) => <BsFillXCircleFill {...props} />,
  cancel: (props) => <BsFillXCircleFill {...props} />,
  logout: (props) => <RiLogoutBoxRFill {...props} />,
  login: (props) => <RiLoginBoxFill {...props} />,
  save: (props) => <BsSave2Fill {...props} />,
  print: (props) => <BsPrinterFill {...props} />,
  confirm: (props) => <BsFillCheckCircleFill {...props} />,
  forgetPassword: (props) => <BsLockFill {...props} />,
  back: (props) => <BsArrowLeftCircleFill {...props} />,
  create: (props) => <BsFillPlusCircleFill {...props} />,
  reAct: (props) => <BsArrowClockwise {...props} />,
  recheck: (props) => <BsShieldFillCheck {...props} />,
  register: (props) => <BsPersonCheckFill {...props} />,
  settings: (props) => <IoSettingsOutline {...props} />,
  threeDots: (props) => <HiDotsCircleHorizontal {...props} />,
  downCircle: (props) => <FaChevronCircleDown {...props} />,
  bookmark: (props) => <IoBookmarks {...props} />,
  alert: (props) => <IoAlertCircleSharp {...props} />,
  checkboxSquare: (props) => <IoCheckbox {...props} />,
  wavingHand: (props) => <MdWavingHand {...props} />,
  external: (props) => <FaExternalLinkAlt {...props} />,
  /** MENU ICON */
  dashboard: (props) => <BsKanban {...props} />,
  todo: (props) => <BsJournalBookmarkFill {...props} />,
  formExample: (props) => <BsLayoutSidebarInsetReverse {...props} />,

  //telesales
  userOutline: (props) => <AiOutlineUser {...props} />,
  userFill: (props) => <FaUser {...props} />,
  editOutline: (props) => <AiOutlineForm {...props} />,
  callOutline: (props) => <BiPhone {...props} />,
  phone: (props) => <FaPhone {...props} />,
  moreVertical: (props) => <BiDotsVerticalRounded {...props} />,
  editCall: (props) => <AiOutlineEdit {...props} />,
  callHistory: (props) => <AiOutlineHistory {...props} />,
  externalLink: (props) => <HiOutlineExternalLink {...props} />,
  checkCircle: (props) => <AiFillCheckCircle {...props} />,
  crossCloseCircle: (props) => <AiOutlineCloseCircle {...props} />,
  check: (props) => <AiOutlineCheck {...props} />,
  crossClose: (props) => <AiOutlineClose {...props} />,
};
