import React from 'react';
import PropTypes from 'prop-types';
import { LinkStyled } from './styled-component';
import { Icon } from '../../resources';

function LinkComponent({ children, href, isShowIcon, isNewTab, rel, style }) {
  return (
    <LinkStyled
      href={href}
      target={isNewTab ? '_blank' : '_self'}
      rel={isNewTab ? 'noopener noreferrer' : rel}
      style={style}
    >
      {children} {isShowIcon && <Icon.externalLink style={{ verticalAlign: 'middle' }} />}
    </LinkStyled>
  );
}

LinkComponent.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  isNewTab: PropTypes.bool,
  rel: PropTypes.string,
  style: PropTypes.object,
  isShowIcon: PropTypes.bool,
};

LinkComponent.defaultProps = {
  children: null,
  href: '#',
  isNewTab: false,
  rel: 'noopener noreferrer',
  style: {},
  isShowIcon: true,
};

export default LinkComponent;
