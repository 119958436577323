import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES_PATH, ROUTES_MENU } from '../../../resources/routes-name';
import { useAuthLogin } from '../../../hooks/useAuth';

export const useSelectMenu = () => {
  const { checkLoginToken } = useAuthLogin();
  const history = useHistory();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(null);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(null);

  useEffect(() => {
    window.addEventListener(
      'storage',
      () => {
        window.location.reload();
      },
      [],
    );
    for (const mn in ROUTES_PATH) {
      if (ROUTES_PATH[mn].PATH === window.location.pathname) {
        setDefaultSelectedKeys([ROUTES_PATH[mn].KEY]);
        for (const rm of ROUTES_MENU) {
          if (rm.children && rm.children.map((e) => e.key).includes(ROUTES_PATH[mn].KEY)) {
            setDefaultOpenKeys(() => [rm.key]);
            break;
          }
          setDefaultOpenKeys([]);
        }
        break;
      }
      setDefaultSelectedKeys([]);
    }
    checkLoginToken();
  }, []);

  const onHandleClick = ({ key }) => {
    history.push(ROUTES_PATH[key.split('/')[1]].PATH);
  };

  return { defaultSelectedKeys, defaultOpenKeys, onHandleClick };
};
