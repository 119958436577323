import React from 'react';
import { ButtonTheme } from '../../../../../components/buttons';
import { color, Icon } from '../../../../../resources';
import Dropdown from '../../../../../components/dropdown';

function ContactActionDropdown({ actions }) {
  const renderOptions = [
    {
      label: 'ดูประวัติการโทร',
      color: color.blue100,
      itemProps: {
        onClick: actions.onToggleHistoryCall,
      },
    },
    {
      label: 'แก้ไขข้อมูล',
      color: color.blue100,
      itemProps: {
        onClick: actions.onToggleEdit,
      },
    },
    {
      label: 'ลบผู้ติดต่อ',
      color: color.redStatus,
      itemProps: {
        onClick: actions.onDeleteContact,
      },
    },
  ];

  return (
    <Dropdown options={renderOptions} placement="bottomRight">
      <ButtonTheme isCircle useFor="CUSTOM" type="primary" icon={<Icon.moreVertical size={16} />} />
    </Dropdown>
  );
}

export default ContactActionDropdown;
