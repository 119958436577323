import React from 'react';
import { column } from './constants/column';
import TableCustom from '../../components/table-custom';
import { useModalContext } from '../../contexts/modal-context';

const tableProps = {
  scroll: { x: 'max-content' },
  style: {
    margin: 'unset',
    padding: '8px',
    backgroundColor: 'white',
  },
};

function TelesalesTable({ dataSource, options, pageStep, isLoading }) {
  const { openModal } = useModalContext();

  const onToggleContact = (companyDetails) => {
    openModal('CONTACT_MODAL', { companyDetails: companyDetails });
  };

  const onToggleHistoryCall = (companyId) => {
    openModal('HISTORY_CALL_MODAL', { companyId: companyId });
  };

  const onToggleEditCallInfo = (companyDetails) => {
    openModal('EDIT_CALL_INFOMATION_MODAL', { companyDetails: companyDetails });
  };

  const onTogglePackageModal = (companyDetails) => {
    openModal('PACKAGE_ITEMS_MODAL', { companyDetails: companyDetails });
  };

  const onToggleNoteModal = (companyDetails) => {
    openModal('NOTE_MODAL', { companyDetails: companyDetails });
  };

  const onToggleAdditionalNoteModal = (companyDetails) => {
    openModal('NOTE_MODAL', { companyDetails: companyDetails, title: 'โน้ตข้อความเพิ่มเติม' });
  };

  const onToggleQuotationModal = (companyDetails, quotations) => {
    openModal('QUOTATION_MODAL', { companyDetails: companyDetails, quotations: quotations });
  };

  return (
    <>
      <TableCustom
        loading={isLoading}
        columns={column({
          onToggleContact,
          onToggleHistoryCall,
          onToggleEditCallInfo,
          onTogglePackageModal,
          onToggleNoteModal,
          onToggleQuotationModal,
          onToggleAdditionalNoteModal,
          options,
          pageStep,
        })}
        dataSource={dataSource}
        isShowStripe
        hasPagination={false}
        {...tableProps}
      />
    </>
  );
}

export default TelesalesTable;
