import styled, { css } from 'styled-components';
import { color } from '../../resources';

export const StyledText = styled.p`
  white-space: pre-line;
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.size && `font-size: ${props.size}px`};
  ${(props) => props.weight && `font-weight: ${props.weight}`};
  &:hover {
    ${(props) => props.hoverUnderline && `text-decoration: underline`};
  }
  ${(props) =>
    props.isLink &&
    css`
      color: ${color.blue80};
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 80%;
      }
    `}
`;

export const StyledTextInLine = styled.span`
  white-space: pre-line;
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.size && `font-size: ${props.size}px`};
  ${(props) => props.weight && `font-weight: ${props.weight}`};

  &:hover {
    ${(props) => props.hoverUnderline && `text-decoration: underline`};
  }

  ${(props) =>
    props.isLink &&
    css`
      color: ${color.blue80};
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 80%;
      }
    `}
`;

export const OverrideStyles = {
  textRefOTP: {
    marginBottom: '0.55rem',
    fontSize: '18px',
    textAlign: 'center',
  },
};
