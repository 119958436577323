export const FORM_SEARCH = ({ options }) => {
  return [
    {
      name: 'caNumber',
      label: 'รหัสบริษัท',
      type: 'INPUT',
      placeholder: 'กรอกรหัสบริษัท',
      span: 12,
    },
    {
      name: 'name',
      label: 'ชื่อบริษัท',
      type: 'INPUT',
      placeholder: 'กรอกชื่อบริษัท',
      span: 12,
    },
    {
      name: 'stageAuto',
      label: 'Stage Auto',
      placeholder: 'เลือก Stage Auto',
      type: 'DROPDOWN',
      span: 8,
      loading: options?.stageAuto?.isLoading,
      properties: {
        options: options?.stageAuto?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'keySuccess',
      label: 'Key Success',
      type: 'DROPDOWN',
      placeholder: 'เลือก Key Success',
      span: 8,
      loading: options?.keySuccess?.isLoading,
      properties: {
        options: options?.keySuccess?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'stageManual',
      label: 'Stage Manual',
      placeholder: 'เลือก Stage Manual',
      type: 'DROPDOWN',
      span: 8,
      loading: options?.stageManual?.isLoading,
      properties: {
        options: options?.stageManual?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },

    {
      name: 'salesName',
      label: 'เซลส์',
      type: 'DROPDOWN',
      placeholder: 'เลือกเซลส์',
      span: 12,
      loading: options?.admin?.isLoading,
      properties: {
        options: options?.admin?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'lastedCall',
      label: 'โทรล่าสุด/ครั้ง',
      type: 'DATE_RANGE',
      format: 'YYYY/MM/DD',
      span: 12,
    },
  ];
};
