import { Tag } from 'antd';
import styled, { css } from 'styled-components';
import { color } from '../../resources';

export const PackageTag = styled(Tag)`
  text-align: center;
  ${(props) => {
    switch (props.status) {
      case 'ACTIVE':
        return css`
          color: ${color.warningGreen} !important;
          background-color: ${color.grey80} !important;
          border-color: ${color.warningGreen} !important ;
        `;

      case 'INACTIVE':
        return css`
          color: ${color.grey500} !important;
          background-color: ${color.grey90} !important;
          border-color: ${color.grey500} !important ;
        `;

      case 'EXPIRED':
        return css`
          color: ${color.redStatus} !important;
          background-color: ${color.red80} !important;
          border-color: ${color.redStatus} !important ;
        `;

      default:
        return css`
          color: ${color.warningGreen} !important;
          background-color: ${color.grey80} !important;
          border-color: ${color.warningGreen} !important ;
        `;
    }
  }};
`;
