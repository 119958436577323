import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { ConfigProvider, TimePicker } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import thTh from 'antd/locale/th_TH';
import { renderTypeError } from '../index.jsx';

const TimePickerComponent = ({ control, item, handleChange, setValue }) => {
  const { rules, name, defaultValue, label, disabled, dataCy, ...propsInput } = item;

  const { fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const format = 'HH:mm';

  const onChange = (e) => {
    setValue(name, null);
    handleChange(e, item);
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <ConfigProvider locale={thTh}>
        <TimePicker
          id={name}
          disabled={disabled}
          //defaultValue={dayjs('12:08', format)}
          allowClear
          format={format}
          onChange={onChange}
          {...dataCy}
          {...propsInput}
        />
      </ConfigProvider>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedTimePicker = TimePickerComponent;
