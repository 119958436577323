import React from 'react';
import { Divider, Space } from 'antd';
import EditForm from './views/edit-form';
import { color } from '../../../../resources';
import Column from '../../../../components/column';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import Information from '../../components/information';
import { useFormState } from './views/edit-form/hooks/useFormState';
import { useOptionsContext } from '../../contexts/options-context';

function EditCallInformationModal(props) {
  const { companyDetails, ...modalProps } = props;
  const { options } = useOptionsContext();

  const { formProps, onSubmit, onCancel } = useFormState({
    companyDetails,
    onCloseModal: props.onToggle,
    options,
  });

  const onCloseModal = () => {
    onCancel({
      onAfterFinish: props.onToggle,
    });
  };

  return (
    <ModalTheme
      width={1000}
      title="แก้ไขข้อมูล"
      {...modalProps}
      onToggle={onCloseModal}
      footer={<Footer onSubmit={onSubmit} onCancel={onCloseModal} />}
    >
      <Column gap="2px">
        <Information {...companyDetails} />
        <Divider />
        <EditForm {...formProps} />
      </Column>
    </ModalTheme>
  );
}

function Footer({ onSubmit, onCancel }) {
  return (
    <Space>
      <ButtonTheme
        useFor="CUSTOM"
        title="ยกเลิก"
        type="primary"
        color={color.grey400}
        onClick={onCancel}
      />
      <ButtonTheme
        useFor="CUSTOM"
        title="บันทึก"
        type="primary"
        color={color.blue80}
        onClick={onSubmit}
      />
    </Space>
  );
}

export default EditCallInformationModal;
