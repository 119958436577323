export const AUTHEN_LOGIN_REQ = '@AUTHEN/LOGIN_REQ';
export const AUTHEN_LOGIN_SUCCESS = '@AUTHEN/LOGIN_SUCCESS';
export const AUTHEN_LOGIN_FAIL = '@AUTHEN/LOGIN_FAIL';

export const AUTHEN_LOGOUT_REQ = '@AUTHEN/LOGOUT_REQ';
export const AUTHEN_LOGOUT_SUCCESS = '@AUTHEN/LOGOUT_SUCCESS';
export const AUTHEN_LOGOUT_FAIL = '@AUTHEN/LOGOUT_FAIL';

export const AUTHEN_LOGIN_GOOGLE_REQ = '@AUTHEN/LOGIN_GOOGLE_REQ';
export const AUTHEN_LOGIN_GOOGLE_SUCCESS = '@AUTHEN/LOGIN_GOOGLE_SUCCESS';
export const AUTHEN_LOGIN_GOOGLE_FAIL = '@AUTHEN/LOGIN_GOOGLE_FAIL';

export const AUTHEN_GET_PROFILE_REQ = '@AUTHEN/GET_PROFILE';
export const AUTHEN_GET_PROFILE_SUCCESS = '@AUTHEN/GET_PROFILE_SUCCESS';
export const AUTHEN_GET_PROFILE_FAIL = '@AUTHEN/GET_PROFILE_FAIL';

export const CHECK_TOKEN_AUTHEN_LOGIN_REQ = '@AUTHEN/CHECK_TOKEN_AUTHEN_LOGIN_REQ';
export const CHECK_TOKEN_AUTHEN_LOGIN_SUCCESS = '@AUTHEN/CHECK_TOKEN_AUTHEN_LOGIN_SUCCESS';
export const CHECK_TOKEN_AUTHEN_LOGIN_FAIL = '@AUTHEN/CHECK_TOKEN_AUTHEN_LOGIN_FAIL';
