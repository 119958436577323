import React from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useAuthLogin } from '../../hooks/useAuth';
import { ImageLogin, LoginCard, LoginForm } from './view/login';
import { env } from '../../utils/env/config';

const LoginScene = () => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { redirectToMain, onLogin } = useAuthLogin();

  useEffect(() => {
    redirectToMain();
  }, []);

  const goToExternalLogin = () => (window.location.href = env.admin_web);

  return (
    <LoginCard>
      <ImageLogin />
      <LoginForm {...{ control, setValue, getValues, handleSubmit, onLogin, goToExternalLogin }} />
    </LoginCard>
  );
};

export default LoginScene;
