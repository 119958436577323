const ROLE_CONSTANT = {
  SUPER_ADMIN: {
    label: 'Super admin',
    color: 'white',
  },
  ADMIN: {
    label: 'admin',
    color: 'white',
  },
  ACCOUNTING_ADMIN: {
    label: 'Accounting admin',
    color: 'white',
  },
  TELESALE: {
    label: 'Telesales',
    color: 'white',
  },
};

export const getRoleDisplay = (role) => {
  return ROLE_CONSTANT[role]?.label || '-';
};

export const getColorRole = (role) => {
  return ROLE_CONSTANT[role]?.color || '-';
};
