import { Col, Row } from 'antd';
import React from 'react';
import { INFORMATION_HEADER } from './constants/info-header';
import { Icon } from '../../../../../../resources';
import { getBadgeColor } from '../../../../../../utils/company';
import { ContactName } from '../../../contact-modal/components/render-column';

function ContactInformation(props) {
  const { companyDetails, contactDetails } = props;

  return (
    <div>
      <Row gutter={[16, 16]} align="middle">
        {INFORMATION_HEADER.map((header, index) => (
          <Col span={header?.span} key={`on_call_information_header_${index}`}>
            {header?.name}
          </Col>
        ))}
      </Row>
      <Row gutter={[16, 16]} align="middle">
        <Col span={8}>
          {companyDetails?.company?.name ? (
            <div style={{ display: 'inline' }}>
              {companyDetails?.company?.name}{' '}
              <Icon.checkCircle
                style={{
                  verticalAlign: 'middle',
                  color: getBadgeColor(companyDetails?.company?.status),
                }}
              />
            </div>
          ) : (
            '-'
          )}
        </Col>
        <Col span={4}>
          <ContactName data={contactDetails?.name} records={contactDetails} align="left" />
        </Col>
        <Col span={4}>{contactDetails?.position || '-'}</Col>
        <Col span={4}>{contactDetails?.phone || '-'}</Col>
        <Col span={4}>{contactDetails?.email || '-'}</Col>
      </Row>
    </div>
  );
}

export default ContactInformation;
