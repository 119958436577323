import { useForm } from 'react-hook-form';
import { schema } from '../forms/yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const useFormState = ({ newDefaultValues = {}, options }) => {
  const defaultValues = {
    note: '',
    keySuccess: '',
    stageManual: '',
    followUpDate: '',
    ...newDefaultValues,
  };

  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const formProps = {
    control: control,
    setValue: setValue,
    getValues: getValues,
    options: options || {},
  };

  const onSubmit = ({ onAction = () => {} }) => {
    handleSubmit(
      (formValues) => {
        onAction(formValues);
      },
      () => {},
    )();
  };

  return {
    formProps,
    onSubmit,
  };
};
