export const FORM_INPUT = () => {
  return [
    {
      name: 'name',
      label: 'ชื่อผู้ติดต่อ',
      type: 'INPUT',
      placeholder: 'กรอกชื่อผู้ติดต่อ',
      span: 12,
    },
    {
      name: 'position',
      label: 'ตำแหน่ง',
      type: 'INPUT',
      placeholder: 'ตำแหน่งผู้ติดต่อ',
      span: 12,
    },
    {
      name: 'phone',
      label: 'เบอร์โทรศัพท์ติดต่อ',
      type: 'INPUT',
      placeholder: 'กรอกเบอร์โทรศัพท์ติดต่อ',
      span: 12,
      rules: {
        required: true,
      },
    },
    {
      name: 'email',
      label: 'อีเมลติดต่อ',
      type: 'INPUT',
      placeholder: 'อีเมลติดต่อ',
      span: 12,
    },
    {
      name: 'isFavorite',
      type: 'CHECKBOX_SINGLE',
      span: 24,
      label: 'ผู้ติดต่อหลัก',
      value: false,
    },
  ];
};
