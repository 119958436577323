import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style.js';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import { useEffect } from 'react';
import { Switch } from 'antd';
import { renderTypeError } from '../index.jsx';

const SwitchComponent = ({ control, item, handleChange, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, propperties, dataCy, ...propsInput } = item;

  const { fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;

  useEffect(() => {
    setValue(name, item.defaultValue || false);
  }, []);

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Switch
        id={name}
        disabled={disabled}
        {...propperties}
        defaultChecked={item.defaultValue || false}
        onChange={(e) => handleChange(e, item)}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedSwitch = SwitchComponent; //memo(, compareRender);
