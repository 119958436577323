import React from 'react';
import TelesalesTable from './table';
import SearchInput from './search-input';
import Pagination from '../components/pagination';
import { Space } from 'antd';
import useTableAction from '../hooks/use-table-action';
import { useOptionsContext } from '../contexts/options-context';

function TelesalesView() {
  const { dataSource, isLoading, error, paginationProps, searchInputProps, exportButtonProps } =
    useTableAction();

  const { options } = useOptionsContext();

  return (
    <Space
      direction="vertical"
      style={{ display: 'flex', gap: '8px', padding: '8px 0', backgroundColor: 'white' }}
    >
      <SearchInput options={options} {...searchInputProps} />

      <Pagination {...paginationProps}>
        <TelesalesTable
          dataSource={dataSource}
          pageStep={paginationProps?.pageStep}
          isLoading={isLoading}
          options={options}
        />
      </Pagination>
    </Space>
  );
}

export default TelesalesView;
