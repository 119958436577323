import React from 'react';
import PropTypes from 'prop-types';
import { RowWrapper } from './styled-component';

const Row = ({ isFluid, wrap, children, justifyContent, alignItems, gap, padding, style }) => {
  return (
    <RowWrapper
      isFluid={isFluid}
      wrap={wrap}
      gap={gap}
      align={alignItems}
      content={justifyContent}
      padding={padding}
      style={style}
    >
      {children}
    </RowWrapper>
  );
};

Row.propTypes = {
  isFluid: PropTypes.bool,
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  children: PropTypes.node,
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  style: PropTypes.object,
  gap: PropTypes.string,
  padding: PropTypes.string,
};

Row.defaultProps = {
  isFluid: false,
  children: null,
  wrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  style: {},
  gap: null,
  padding: null,
};

export default Row;
