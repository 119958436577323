import React from 'react';
import { ModalAntd } from './styled-component';
import { ConfigProvider } from 'antd';

export const ModalTheme = (props) => {
  const { open, title, onToggle, children, ...otherProps } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            wireframe: true,
            motion: false,
          },
        },
      }}
    >
      <ModalAntd
        title={title || 'Basic Modal'}
        open={open}
        onOk={onToggle}
        onCancel={onToggle}
        maskClosable={false}
        destroyOnClose={true}
        footer={null}
        {...otherProps}
      >
        {children}
      </ModalAntd>
    </ConfigProvider>
  );
};
