import { Yup } from '../../../../../../../helpers/yup';

export const schema = Yup.object().shape({
  keySuccess: Yup.string(),
  stageManual: Yup.string(),
  purchaseOccasion: Yup.string(),
  quotations: Yup.array(),
  knowUs: Yup.array(),
  totalCandidate: Yup.string(),
  positionReceived: Yup.string(),
  jobNeed: Yup.string(),
  avgJobpost: Yup.string(),
  avgSearch: Yup.string(),
  favPosition: Yup.string(),
  findPosition: Yup.array(),
  competitors: Yup.array(),
  budgetPeriod: Yup.string(),
  budget: Yup.string(),
  additionalNote: Yup.string(),
});
