export const STATUS = {
  silver: 'silver',
  blue: 'blue',
  official: 'official',
};

export const VERIFY_MESSAGE = {
  silver: 'Silver Verified',
  blue: 'Blue Verified',
  official: 'Official Verified',
};
