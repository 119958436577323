import styled from 'styled-components';
import { color } from '../../resources';

export const LinkStyled = styled.a`
  color: ${color.grey900};

  .svg {
    color: ${color.grey900};
  }
  :hover {
    color: ${color.grey900};
    text-decoration: underline;
    opacity: 80%;
  }
`;
