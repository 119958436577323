import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { navigationRef } from './utils/navigation';
import GlobalStyle from './styles/global-style';
import * as ROUTES from './resources/routes-name';
import HandleScene from './screens/handle';
import { Fragment } from 'react';
import { App as AppAntD } from 'antd';
import LoginScene from './screens/login';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import Alert from './components/alert/alert';
import Loading from './components/loading/loading.jsx';
import LayoutPage from './components/layoutPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// if (env.node_env !== 'development') {
//   console.log = () => null;
//   console.error = () => null;
// }

const AppContainer = () => {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <Loading>
          <Alert />
          <Router ref={navigationRef}>
            <div />
            <Switch>
              <Route exact path={ROUTES.ROUTE_HANDLE} component={HandleScene} />
              <Route exact component={LayoutPage} />
            </Switch>
          </Router>
        </Loading>
      </Provider>
    </QueryClientProvider>
  );
};

const App = () => {
  return (
    <Fragment>
      <AppAntD>
        <AppContainer />
        <GlobalStyle />
      </AppAntD>
    </Fragment>
  );
};

export default App;
