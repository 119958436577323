import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  flex-wrap: ${(props) => props.wrap};
  align-items: ${(props) => (props.align ? `${props.align}` : 'center')};
  justify-content: ${(props) => (props.content ? `${props.content}` : 'space-between')};
  padding: ${(props) => (props.padding ? `${props.padding}` : 'unset')};
  gap: ${(props) => (!props.gap ? '0' : `${props.gap}`)};
  ${(props) => props.isFluid && `width:100%;`}
`;
