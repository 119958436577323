import styled from 'styled-components';
import { Card } from 'antd';

export const CardContainerStyle = styled.div`
  border-radius: 15px;
  margin: 20px;
`;

export const CardStyle = styled(Card)`
  border-radius: 15px;
  margin: 20px;
`;
