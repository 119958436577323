import React from 'react';
import { PurchaseOccasionColumn } from '../../../../table/components/render-column';

export const FORM_INPUT = ({ options, isDisabledKeySuccess }) => {
  return [
    {
      name: 'keySuccess',
      label: 'Key Success',
      type: 'DROPDOWN',
      placeholder: 'เลือก Key Success',
      disabled: isDisabledKeySuccess,
      span: 12,
      loading: options?.keySuccess?.isLoading,
      properties: {
        options: options?.keySuccess?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'stageManual',
      label: 'Stage Manual',
      placeholder: 'เลือก Stage Manual',
      type: 'DROPDOWN',
      span: 12,
      loading: options?.stageManual?.isLoading,
      properties: {
        options: options?.stageManual?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'purchaseOccasion',
      label: 'โอกาสในการซื้อ',
      placeholder: 'เลือกโอกาสในการซื้อ',
      type: 'DROPDOWN',
      span: 12,
      loading: options?.purchaseOccasion?.isLoading,

      properties: {
        options: options?.purchaseOccasion?.data || [],
        valueKey: 'value',
        labelKey: 'label',
        optionRender: (value) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <PurchaseOccasionColumn data={value} />
          </div>
        ),
      },
    },
    {
      name: 'quotations',
      label: 'ใบเสนอราคา',
      placeholder: 'เลือกใบเสนอราคา',
      type: 'DROPDOWN',
      mode: 'multiple',
      span: 12,
      loading: options?.quotation?.isLoading,

      properties: {
        options: options?.quotation?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'knowUs',
      label: 'ช่องทางที่รู้จัก JobFinFin',
      type: 'DROPDOWN',
      placeholder: 'เลือกช่องทางที่รู้จัก JobFinFin',
      mode: 'tags',
      span: 8,
      loading: options?.knowUs?.isLoading,
      properties: {
        options: options?.knowUs?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'totalCandidate',
      label: 'จำนวนคนที่ได้จาก JobFinFin',
      type: 'INPUT',
      placeholder: 'กรอกจำนวนคนที่ได้จาก JobFinFin',
      span: 8,
    },
    {
      name: 'positionReceived',
      label: 'ตำแหน่งที่ได้จาก JobFinFin',
      type: 'INPUT',
      placeholder: 'กรอกตำแหน่งที่ได้จาก JobFinFin',
      span: 8,
    },
    {
      name: 'avgJobPost',
      label: 'ประกาศที่ใช้เฉลี่ยต่อเดือน',
      type: 'INPUT',
      placeholder: 'กรอกจำนวนประกาศที่ใช้เฉลี่ยต่อเดือน',
      span: 12,
    },
    {
      name: 'avgSearch',
      label: 'เครดิตค้นหาที่ใช้เฉลี่ยต่อเดือน',
      type: 'INPUT',
      placeholder: 'กรอกจำนวนเครดิตค้นหาที่ใช้เฉลี่ยต่อเดือน',
      span: 12,
    },
    {
      name: 'favPosition',
      label: 'หาตำแหน่งอะไรบ้าง',
      type: 'INPUT',
      placeholder: 'กรอกตำแหน่งที่หา',
      span: 12,
    },
    {
      name: 'findPosition',
      label: 'สายงานที่บริษัทนี้หา',
      placeholder: 'เลือกสายงานที่บริษัทนี้หา (เลือกได้หลายอัน)',
      type: 'DROPDOWN',
      mode: 'multiple',
      span: 12,
      properties: {
        options: options?.jobCat?.data || [],
        valueKey: 'value',
        labelKey: 'value',
      },
    },
    {
      name: 'competitors',
      label: 'เว็บหางานอื่นๆที่ใช้',
      placeholder: 'กรอกเว็บหางานอื่นๆที่ใช้',
      type: 'DROPDOWN',
      mode: 'tags',
      span: 8,
      loading: options?.competitors?.isLoading,
      properties: {
        options: options?.competitors?.data || [],
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      name: 'budgetPeriod',
      label: 'รอบอนุมัติงบประมาณ',
      type: 'DATE_PICKER',
      placeholder: 'กรอกรอบอนุมัติงบประมาณ',
      span: 8,
      picker: 'month',
    },
    {
      name: 'budget',
      label: 'งบประมาณ',
      type: 'INPUT',
      placeholder: 'ระบุงบประมาณ หรือ รายละเอียดงบประมาณ',
      span: 8,
    },
    {
      name: 'additionalNote',
      label: 'โน้ตข้อความเพิ่มเติม',
      type: 'INPUT_AREA',
      placeholder: 'โน้ตข้อความเพิ่มเติม',
      span: 24,
      rows: 4,
    },
  ];
};
