import React from 'react';
import Row from '../../../../../row';
import Column from '../../../../../column';
import { TELESALES_VERSION } from '../../../../../../resources/constants/version';
import LogoJFF from '../../../../../../assets/images/svg/logo-jff.svg';
import Text from '../../../../../text-new';
import { color } from '../../../../../../resources';

function BrandBanner() {
  return (
    <Row
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
      padding="16px 16px"
    >
      <img
        style={{
          width: '54px',
          height: '54px',
          minWidth: '54px',
          minHeight: '54px',
          borderRadius: '50%',
        }}
        src={LogoJFF}
        alt="Jobfinfin Banner"
      />

      <Column isFluid alignItems="flex-start" justifyContent="center">
        <Text variant="span" size={17} color={color.grey200}>
          Telesales jobfinfin
        </Text>
        <Text variant="span" size={16} color={color.grey200}>
          {TELESALES_VERSION}
        </Text>
      </Column>
    </Row>
  );
}

export default BrandBanner;
