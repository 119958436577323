import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as tableApi from '../services';
import { closeLoading, openAlert, openLoading } from '../../../components/alert/useAlert';

export const useTableListQuery = (filter) => {
  const query = useQuery({
    queryKey: ['tabledata', filter],
    queryFn: () => tableApi.getList(filter),
    keepPreviousData: true,
  });

  return query;
};

export const useEditCallDetails = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: 'editCallDetails',
    mutationFn: ({ companyId, reqBody }) => {
      openLoading();
      return tableApi.editDetails({ companyId, reqBody });
    },
    onSuccess: () => {
      closeLoading();
      openAlert({
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
      queryClient.invalidateQueries(['tabledata']);
    },
    onError: () => {
      closeLoading();
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};

export const usePackageList = (companyId) => {
  const query = useQuery({
    queryKey: ['packageData', companyId],
    queryFn: () => tableApi.packageDetails(companyId),
  });

  return query;
};

export const useExportExcel = () => {
  const mutation = useMutation({
    mutationKey: 'excelFiles',
    mutationFn: (reqBody) => {
      return tableApi.exportToExcel(reqBody);
    },
  });

  return mutation;
};
