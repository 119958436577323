import { GET, GET_WITH_PARAMS, POST } from '../../../services';

export const getList = async (params) => {
  try {
    const response = await GET_WITH_PARAMS(`/tls-company/company`, params);
    return response.result;
  } catch (err) {
    throw new Error('error');
  }
};

export const editDetails = async ({ companyId, reqBody }) => {
  try {
    await POST(`/tls-company/update-company/${companyId}`, reqBody);
  } catch (err) {
    throw new Error('error');
  }
};

export const packageDetails = async (companyId) => {
  try {
    const response = await GET(`/tls-company/package/${companyId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const exportToExcel = async (reqBody) => {
  try {
    const response = await GET(`/tls-company/export`, reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};
