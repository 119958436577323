import React from 'react';
import TelesalesView from './views';
import { ModalProvider } from './contexts/modal-context';
import { OptionsProvider } from './contexts/options-context';

function TelesalesScene() {
  return (
    <OptionsProvider>
      <ModalProvider>
        <TelesalesView />
      </ModalProvider>
    </OptionsProvider>
  );
}

export default TelesalesScene;
