import React from 'react';
import { ButtonTheme } from '../../../../components/buttons';
import { ModalTheme } from '../../../../components/modal';
import { color } from '../../../../resources';
import { Divider, Space } from 'antd';
import ContactInformation from './views/contact-information';
import ContactForm from './views/contact-form';
import CallTimer from './views/call-timer';
import Row from '../../../../components/row';
import useCallAction, { CALL_STATUS } from '../../hooks/use-call-action';
import { useFormState } from './views/contact-form/hooks/useFormState';
import { useOptionsContext } from '../../contexts/options-context';

function CallModal(props) {
  const { companyId, contactDetails, companyDetails, onCallDetails, ...modalProps } = props;
  const { options } = useOptionsContext();

  const onClose = () => {
    props?.onToggle({ defaultValues: {} });
  };

  const { formProps, onSubmit } = useFormState({
    newDefaultValues: {
      keySuccess: companyDetails?.keySuccess || '',
      stageManual: companyDetails?.stageManual || '',
      purchaseOccasion: companyDetails?.purchaseOccasion,
      knowUs: companyDetails?.knowUs,
      totalCandidate: companyDetails?.totalCandidate,
      positionReceived: companyDetails?.positionReceived,
      avgJobPost: companyDetails?.avgJobPost,
      avgSearch: companyDetails?.avgSearch,
      favPosition: companyDetails?.favPosition,
      findPosition: companyDetails?.findPosition,
      competitors: companyDetails?.competitors,
      budgetPeriod: companyDetails?.budgetPeriod,
      budget: companyDetails?.budget,
      quotations: companyDetails?.quotations.map((items) => items?.quotationNo) || [],
      additionalNote: companyDetails?.additionalNote,
    },
    options,
  });

  const { callTimerProps, onSubmitTransaction } = useCallAction({
    contactId: contactDetails?._id,
    companyId,
    onCallDetails,
    onSubmit,
  });

  return (
    <ModalTheme
      width={1000}
      title="โทรติดต่อ"
      footer={
        <Footer
          isDisabled={callTimerProps?.status !== CALL_STATUS.WAITING_CONFIRM}
          onSubmit={() =>
            onSubmitTransaction({
              onCallBack: onClose,
            })
          }
          onClose={onClose}
        />
      }
      {...modalProps}
    >
      <ContactInformation companyDetails={companyDetails} contactDetails={contactDetails} />
      <Divider />
      <CallTimer {...callTimerProps} />
      <ContactForm {...formProps} />
    </ModalTheme>
  );
}

function Footer({ isDisabled, onSubmit, onClose }) {
  return (
    <Row justifyContent="flex-end" alignItems="center">
      <Space>
        <ButtonTheme
          useFor="CUSTOM"
          title="ยกเลิก"
          type="primary"
          color={color.grey400}
          onClick={onClose}
        />
        <ButtonTheme
          useFor="CUSTOM"
          title="บันทึก"
          type="primary"
          disabled={isDisabled}
          color={color.blue80}
          onClick={onSubmit}
        />
      </Space>
    </Row>
  );
}

export default CallModal;
