import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as contactApi from '../services';
import { openAlert } from '../../../components/alert/useAlert';

export const useContactCompanyQuery = (companyId) => {
  const query = useQuery({
    queryKey: ['contactCompany', companyId],
    queryFn: () => contactApi.getContactCompany(companyId),
  });

  return query;
};

export const useAddContact = ({ onCallback }) => {
  const mutation = useMutation({
    mutationKey: 'addContact',
    mutationFn: (reqBody) => contactApi.addContact(reqBody),
    onSuccess: () => {
      openAlert({
        type: 'success',
        message: 'สร้างผู้ติดต่อสำเร็จ',
      });
      onCallback();
    },
    onError: () => {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};

export const useEditContact = ({ onCallback }) => {
  const mutation = useMutation({
    mutationKey: 'editContact',
    mutationFn: ({ contactId, reqBody }) => contactApi.editContact(contactId, reqBody),
    onSuccess: () => {
      onCallback();
      openAlert({
        type: 'success',
        message: 'แก้ไขผู้ติดต่อสำเร็จ',
      });
    },
    onError: () => {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: 'deleteContact',
    mutationFn: (contactId) => contactApi.deleteContact(contactId),
    onSuccess: () => {
      openAlert({
        type: 'success',
        message: 'ลบผู้ติดต่อสำเร็จ',
      });
      queryClient.invalidateQueries(['contactCompany']);
    },
    onError: () => {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};
