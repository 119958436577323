import React from 'react';
import { ContactActions, ContactName } from '../components/render-column';

export const column = ({ onToggleEdit, onToggleCall, onToggleHistoryCall, onDeleteContact }) => {
  return [
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: 'name',
      key: 'name',
      render: (data, records) => <ContactName data={data} records={records} />,
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
      width: '100px',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phone',
      key: 'phone',
      width: '100px',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
      width: '100px',
    },
    {
      title: '',
      key: 'action',
      width: 'auto',
      fixed: 'right',
      render: (_, records) => (
        <ContactActions
          actions={{
            onToggleEdit: () => onToggleEdit(records),
            onToggleCall: () => onToggleCall(records),
            onToggleHistoryCall: () => onToggleHistoryCall(records?._id),
            onDeleteContact: () => onDeleteContact(records?._id, records?.name),
          }}
        />
      ),
    },
  ];
};
